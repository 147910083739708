import Account from './account'
import Security from './security'
import Signature from './signature'
import Vue from "vue";

export default {
  name: 'profile',
  components: {
    Account,
    Security,
    Signature
  },
  data() {
    return {
      role: "",
      branch: "",
     }
  },
  mounted(){ 
    this.getRole()
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res?.data.role
      this.branch = res?.data.branch_id
    },
  }
}