import UserModify from '../widget/widget-update-profile'
import Vue from "vue";

export default {
    name: 'account',
    components: {
      UserModify
    },
    data() {
      return {
        updateOpened: false,
        user: {
          id: '',
          image: '',
          username: '',
          branch: '',
          fname: '',
          lname: '',
          tel: '',
          gender: '',
          email: '',
          facebook: '',
          line: '',
          position: ''
        }
      }
    },
    mounted(){
      this.onInitData()
    },
    methods: {
      handleCloseEvent(event) {
        if (event.status) {
          this.updateOpened = false
          this.onInitData()
        }
      },
      onUserUpdate() {
        this.updateOpened = !this.updateOpened
      },
      async onInitData() {
        this.loading = true  
        // const result = await this.$store.dispatch('Auth/getRole', {}, { root: true })

        const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/me`)
        if(result.status === 200) {
          this.userData = result.data

          this.user.fname = result.data.firstname
          this.user.lname = result.data.lastname
          this.user.username = result.data.email
          this.user.email = result.data.email_address
          this.user.branch = result.data.detail?.name
          this.user.tel = result.data.phone_number
          this.user.gender = result.data.gender
          this.user.facebook = result.data.facebook
          this.user.line = result.data.line  
          this.user.position = result.data.role
          this.loading = false
        }


      }
    }
  }