var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"password max-w-sm"},[_vm._m(0),_c('form',{staticClass:"forms-sample"},[_c('a',{staticClass:"btn btn-info font-weight-light py-2.5 rounded-xl text-white text-xs-1/2 font-weight-bold form-control-btn",on:{"click":function($event){return _vm.toggleShow()}}},[_vm._v("อัพโหลดไฟล์ PNG")]),_c('my-upload',{attrs:{"field":"img","width":300,"height":300,"url":_vm.url,"headers":_vm.headers,"noCircle":true,"img-format":"png","lang-ext":{
        hint: 'คลิ๊กหรือลากรูปมาที่นี่',
        loading: 'กำลังอัพโหลด…',
        noSupported:
          'เบราเซอร์ไม่รองรับ, กรุณาใช้ IE เวอร์ชั่น 10 ขึ้นไป หรือใช้เบราเซอร์ตัวอื่น',
        success: 'อัพโหลดสำเร็จ',
        fail: 'อัพโหลดล้มเหลว',
        preview: 'ตัวอย่าง',
        btn: {
          off: 'ยกเลิก',
          close: 'ปิด',
          back: 'กลับ',
          save: 'บันทึก',
        },
        error: {
          onlyImg: 'ไฟล์ภาพ PNG เท่านั้น',
          outOfSize: 'ไฟล์ใหญ่เกินกำหนด: ',
          lowestPx: 'ไฟล์เล็กเกินไป. อย่างน้อยต้องมีขนาด: 300x300',
        },
      }},on:{"crop-success":_vm.cropSuccess,"crop-upload-success":_vm.cropUploadSuccess,"crop-upload-fail":_vm.cropUploadFail},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}),_c('img',{staticClass:"mt-3 form-group",attrs:{"src":_vm.imgDataUrl}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title font-weight-bold text-gray-600"},[_vm._v(" อัพโหลดลายเซ็นนพร้อมตราประทับ สำหรับเอกสาร ")])])
}]

export { render, staticRenderFns }